.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 20px; /* Adjust margin to avoid overlap */
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 20px; /* Adjust padding to avoid overlap */
}

.nav-bar {
  width: 100%;
  background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  position: fixed; /* Make it sticky */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it stays on top */
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.nav-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-logo-img {
  height: 40px;
  pointer-events: none;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
}

.nav-links a:hover {
  background-color: #dddddd5d;
  color: black;
  height: auto;
}

.language-selector img {
  width: 30px;
  height: auto;
  cursor: pointer;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100%;
    right: -100%;
    width: 30%;
    height: 250%;
    background-color: rgba(51, 51, 51, 0.9);
    backdrop-filter: blur(10px);
    transition: right 0.3s ease;
    text-align: center;
    /*box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow effect for the menu */
    z-index: 999; /* Ensure it stays above other elements */
  }

  .nav-links.nav-links-active {
    right: 0;
  }

  .nav-links a {
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .language-selector {
    margin-top: 20px;
  }
}

.email-subscription,
.who-we-are,
.what-we-do,
.footer {
  background-color: #444;
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visual appeal */
}

.email-subscription input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 80%;
  max-width: 400px;
}

.email-subscription button {
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #61dafb;
  border: none;
  color: #282c34;
}

.footer {
  margin-top: auto;
  padding: 20px;
  text-align: center;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #61dafb;
  margin: 0 5px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Additional styling for better page structure */
body {
  margin: 0;
  padding-top: 60px; /* Add padding to avoid content being hidden behind the sticky nav bar */
}

.rounded-section {
  background-color: #444;
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  color: white;
}
